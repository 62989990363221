.clients {
    background-color: #F0F0F0;
    min-height: 135px;
    width: 100%;
}

.clients__wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.clients__imgs {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 135px;
    width: 1038px;
}

.clients__img {
    padding-left: 100px;
}

.clients__img-item1 {
    height: 80px;

}

.clients__img-item2 {
    max-height: 111px;
    margin-bottom: 20px;
}

.clients__img-item3 {
    height: 80px;
}

.clients__title {
    font-size: 22px;
    color: black;
}

@media (max-width: 1050px) {

    .clients__wrap {
        flex-direction: column;
        width: 57%;  
        margin-left: 10px;
    }

    .clients__title {
        padding-top: 25px;
    }

    .clients__imgs {
        flex-direction: column;
        width: 276px;
    }

    .clients__img {
        padding-left: 0px;
        padding-top: 50px;
    }

    .clients__img:nth-child(3) {
        padding-bottom: 50px;
    }
}