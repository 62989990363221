.main-content__wrap {
    max-width: 480px;
    text-align: left;
}

.serv {
    background-image: url('../Style/img/products2.jpeg');
    background-size: cover;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    justify-content: center;
    position: relative;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    margin-bottom: 100px;
    width: 100%;
}

.serv__wrap {
    width: 1169px;
}

.serv__title {
    padding-top: 50px;
    font-size: 22px;
}

.serv__header {
    margin-top: 120px;
}

.serv__desc {
    color: rgba(255, 255, 255, 1);
    line-height: 21.05px;
    font-weight: 400;
    font-size: 22px;
    margin-top: 20px;
}

.button__info {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: black;
    margin-top: 50px;
    border-radius: 25px;
    max-width: 200px;
    margin-bottom: 100px;
}
.arrow {
    margin-left: 5px;
}

.button__info:hover {
    background-color: #659DBF;
    box-shadow: 0px 0px 5px 5px #659DBF;
}

@media (max-width: 1050px) {
    .main-content__wrap {
        padding-left: 3%;
        width: 90%;
    }
}