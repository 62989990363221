.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #24282E;
    margin-top: 30px;
    font-family: 'GothamProRegular';
    font-size: 20px;
    line-height: 46px;
    width: 100%;
}

.footer__wrap {
    display: flex;
    align-items: flex-start;
    padding-top: 30px;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 30px;
    width: 1160px
}

.footer__logo {
    cursor: pointer;
}

.footer__list {
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-transform: uppercase;
    line-height: 46px;
    text-align: left;
    margin-left: 120px;
}

.footer__item {
    color: #9EB0BC;
    text-transform: initial;
    height: 35px;
}

.footer__item:hover {
    color: #fff;
}

.breadcrumbs {
    display: flex;
    justify-content: center;
    color: #9EB0BC;
}

.footer__address {
    display: flex;
    flex-direction: column;
    margin-top: -165px;
    text-align: left;
}

.address__item {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 522px;
    margin-bottom: 15px;
    font-size: 20px;
}

.address__elem {
    margin-left: 20px;
    line-height: 24px;
}

.address__link {
    font-style: normal;
    color: #fff;
    text-decoration: none;
}

.address__link:hover {
    color: #9EB0BC;
}

.footer__link {
    text-decoration: none;
}

@media (max-width: 1281px) {
    .footer {
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .footer__wrap {
        flex-direction: column;
        width: 100%
    }
    .footer__logo {
        margin-left: 10px;
    }
    .footer__list {
        font-weight: bold;
        margin-top: 20px;
        margin-left: 20px;
    }
    .footer__address {
        margin-top: 0px;
        margin-right: 20%;
    }
    .breadcrumbs {
        justify-content: flex-start;
        flex-direction: column-reverse;
        display: flex;
        width: 100%;
        margin-left: 5%;
        align-items: flex-start;
    }
    .address__item {
        width: 100%;
    }
    .allRights {
        line-height: 30px;
        text-align: left;
    }
}