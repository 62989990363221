.empas {
    width: 1206px;
    text-align: left;
}

.empas__title {
    font-family: 'GothamProRegular';
    text-transform: uppercase;
    font-size: 36px;
    border-top: 2px solid #9EB0BC;
    padding-top: 30px;
    padding-bottom: 7px;
    color: rgba(64, 83, 105, 1);
}

.empas__desc {
    font-family: 'GothamProRegular';
    font-weight: 400;
    font-size: 24px;
    line-height: 22.97px;
    color: rgba(64, 83, 105, 1);
    padding-bottom: 40px;
}



.empas__desc-title {
    font-family: 'GothamProRegular';
    text-transform: uppercase;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 20px;
    padding-top: 30px;
}

.empas__desc-text {
    font-family: 'GothamProRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(40, 40, 40, 1);
    padding-bottom: 10px;
}

.lukoil__link {
    color: rgba(20, 80, 128, 1);
    font-family: 'GothamProRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}
.empas__tasks {
    padding-left: 20px;
}
.empas__task {
    font-family: 'GothamProRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    padding-left: 10px;
    list-style: url(../Style/img/listCircle.png);
    padding-bottom: 10px;
} 

@media (max-width: 1220px) {

    .empas {
        max-width: 100%;
    }

    .empas__title,
    .empas__desc,
    .empas__desc-title,
    .empas__desc-text {
        margin-left: 2%;
        margin-right: 2%;
    }
    
    .empas__tasks {
        padding-left: 7%;
        padding-right: 7%;
    }
}