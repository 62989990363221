.SubHeaderService {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    color: black;
}

.service__title {
    font-size: 48px;
}

.service__desc {
    font-size: 22px;
    line-height: 30px;
    margin-top: 20px;
}

.service__desc-text {
    max-width: 1095px;
    text-align: center;
    line-height: 30px;
    margin-top: -60px;
}

.service__buttons-block {
    margin-top: 30px;
}



.service__main-text {
    font-family: 'GothamProRegular';
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: rgba(40, 40, 40, 1);
    margin-bottom: 35px;
}

.service__list {
    display: flex;
    justify-content: space-around;
}

.service__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
    align-items: center;
    background-color: rgba(158, 176, 188, 1);
    height: 207px;
    width: 352px;
    padding: 25px;
}

.service__item:hover {
    background-color: #659DBF;
    box-shadow: 0px 0px 5px 5px #659DBF;
    cursor: pointer;
}

.service__link {
    text-decoration: none;
}
.service__elem {
    margin-right: 40px;
}

.serv-item__title {
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
    font-family: 'GothamProRegular';
    font-weight: 400;
    line-height: 19.14px;
    max-width: 172px;
    margin-left: 30px;
}

.serv-item__desc {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    min-width: 300px;
    color: rgba(1, 1, 1, 1);
    font-family: 'GothamProRegular';
}

@media (max-width: 1281px) {
    .service__desc-text {
        margin-left: 5%;
        margin-right: 5%;
        text-align: left;
    }

    .service__elem {
        margin-bottom: 10px;
    }

    .service__list {
        align-items: center;
    }
}