.develop {
    width: 1200px;
    text-align: left;
}

.develop__title {
    font-family: 'GothamProRegular';
    text-transform: uppercase;
    font-size: 36px;
    border-top: 2px solid #9EB0BC;
    padding-top: 60px;
    padding-bottom: 25px;
    color: rgba(64, 83, 105, 1);
}

.develop__desc {
    font-family: 'GothamProRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(40, 40, 40, 1);
    padding-bottom: 20px;
}

.develop__img {

    padding-bottom: 30px;
}

.lukoil__link {
    color: rgba(20, 80, 128, 1);
    font-family: 'GothamProRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}

.develop__desc-list {
    padding-left: 25px;
    padding-bottom: 20px;
}

.develop__desc-text {
    font-family: 'GothamProRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    padding-left: 10px;
    list-style: url(../Style/img/listCircle.png);
    padding-bottom: 10px;
}

.develop__projects-link {
    text-transform: uppercase;
    color: rgba(20, 80, 128, 1);
    cursor: pointer;
}

@media (max-width: 1100px) {
    .develop {
        max-width: 100%;
    }

    .develop__title {
        padding-left: 3%;
    }

    .develop__desc {
        padding-left: 3%;
    }

    .develop__desc-list {
        padding-left: 10%;
    }
}