.clients-wrap {
    border-top-style: groove;
    border-top-color: #99999947;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.client__title {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 1280px;
    width: 1100px;
    text-align: left;
}

.client__img {
    min-height: 286px;
    background-position: center;
    background-size: cover;
    padding-bottom: 50px;
    padding-top: 50px;
    max-width: 1280px;
    width: 1100px;
    min-height: 250px;
}


.clients-page__imgs {
    padding-top: 30px;
    justify-content: space-between;
}
.client-page__img {
    padding-left: 0px;
}

@media (max-width: 1120px) {
    .clients-wrap {
        align-items: flex-start;
    }
    .client__title {
        margin-left: 5%;
        width: auto;
    }
    .client__img {
        width: 100%;
        background-position: 68% 100%;
    }
    .clients-page__imgs {
        width: 100%;
    }
  
}