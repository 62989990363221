.SubHeaderProjects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    color: black;
}
.project__title {
    font-size: 48px;
}
.project__desc {
    font-size: 22px;
    line-height: 30px;
    margin-top: 20px;
}

.project__desc-text {
    max-width: 1250px;
    text-align: left;
    line-height: 30px;
    padding-top: 20px;
}


@media (min-width: 967px) {
    .SubHeaderProjects {
        margin-left: 15%;
        margin-right: 15%;
    }
}
@media (max-width: 968px) {
    .SubHeaderProjects {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media (max-width: 1281px) {
    .project__desc-text {
        margin-left: 5%;
    }
}