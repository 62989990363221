a {
    text-decoration: none;
}


.contacts-wrap {
    border-top-style: groove;
    border-top-color: #99999947;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact__title {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 1280px;
    width: 1100px;
    text-align: left;
}

.contact__img {
    min-height: 286px;
    background-position: center;
    background-size: cover;
    padding-bottom: 50px;
    padding-top: 50px;
    max-width: 1280px;
    width: 1100px;
    min-height: 250px;
}

.contacts__info {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    margin-top: 30px;
    width: 1100px;
}

.contacts__elem {
    display: flex;
    text-decoration: none;
    align-items: center;
    line-height: 30px;
    margin-right: 30px;
}

.contacts__adds {
    margin-left: 10px;
    text-align: left;
}

.contact__link:hover {
    color: #659DBF;
}

.contacts__desc {
    width: 1100px;
    margin-top: 40px;
    text-align: left;
}

.contacts__desc-title {
    font-family: 'GothamProRegular';
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.contacts__desc-text {
    font-family: 'GothamProRegular';
    line-height: 30px;
    color: #282828;
    font-weight: 400;
    margin-top: 10px;
    width: 1100px;
}
.contacts__map {
    margin-top: 50px;
    width: 1100px;
    height: 450px;
}


.contacts__link-text {
    color:#659DBF;
}
@media (max-width: 1120px) {
    .contacts-wrap {
        align-items: flex-start;
    }
    .contact__title {
        margin-left: 5%;
        width: auto;
    }
    .contact__img {
        width: 100%;
        background-position: 64% 100%;
    }
    .contacts-page__imgs {
        width: 100%;
    }

    .contacts__info {
        width: 100%;
        flex-direction: column;
        padding-left: 5%;
    }

    .contacts__elem {
        margin-bottom: 20px;
    }

    .contacts__desc {
        width: 100%;
        padding-left: 3%;
    }

    .contacts__desc-title {
        text-align: center;
    }

    .contacts__desc-text {
        width: 100%;
    }

    .contacts__map {
        width: 100%;
    }
  
}

