.top_background {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -80px;
    min-height: 700px;
    min-width: 360px;
    width: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
}

.SubHeaderAbout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    color: white;
}

.main__title {
    text-transform: uppercase;
    animation: show_title .7s linear;
    /* margin-top: 50px; */
}

.main__desc {
    text-transform: uppercase;
    margin-top: 25px;
    font-weight: 400;
    line-height: 29px;
    color: rgba(193, 199, 202, 1);
    /* font-size: 20px; */
    animation: show_desc .7s linear;
}

@keyframes show_title {
    0% {
        transform: translateY(-1000px);
    }

    50% {
        transform: translateY(-500px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes show_desc {
    0% {
        transform: translateX(1000px);
    }

    50% {
        transform: translateX(500px);
    }

    100% {
        transform: translateX(0px);
    }
}

@media (min-width: 967px) {
    .main__title {
        font-size: 48px;
        line-height: 60px;
    }
    .main__desc {
        font-size: 22px;
    }
    .SubHeaderAbout {
        margin-left: 15%;
        margin-right: 15%;
        min-height: 205px;
    }
}

@media (min-width: 1200px) {
    .Header {
        max-width: 70%;
        /* padding-left: 20%;
        padding-right: 20%; */
    }
}

@media (max-width: 1119px) {
    .main__title {
        font-size: 32px;
        line-height: 40px;
    }
    .main__desc {
        font-size: 16px;
        line-height: 25px;
    }
    .SubHeaderAbout {
        margin-left: 5%;
        margin-right: 5%;
    }
    .Header {
        justify-content: start;
        padding-right: 5%;
    }
}
