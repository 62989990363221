html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: normal;
}

a {
    color: inherit;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

* {
    box-sizing: border-box;
}

img {
    display: block;
    max-width: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: "GothamProRegular";
    font-size: 20px;
    font-weight: 400;
    color: black;
    background: rgb(255, 255, 255);
    line-height: 46px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Wrapper {
    border-top-style:groove;
    border-top-color: #99999947 ;
    width: 100%;
}

@media (min-width: 1180px) {
    .App {
        align-items: center;
    }
}

@font-face {
    font-family: "GothamProRegular";
    src: url("./fonts/gothampro_bold.ttf") format('truetype');
    src: url("./fonts/gothampro.ttf") format('truetype'),
        url("./fonts/gothampro_medium.ttf") format('truetype'),
        url("./fonts/gothampro_light.ttf") format('truetype');
    font-style: normal;
    font-weight: 400;
}
