.about {
    background-color: rgb(255, 255, 255);
    color: black;
    margin-top: -80px;
    margin-bottom: 20px;
}

.about__title {
    font-weight: 400;
    font-size: 22px;
    padding-bottom: 20px;
}

.about__desc {
    font-weight: 400;
    line-height: 30px;
    
}

.about__desc-title {
    font-size: 23px;
    margin-bottom: 10px;
    margin-top: 25px;
}

.about__desc0 {
    font-size: 18px;
    line-height: 30px;
}

.about__desc1 {
    font-size: 17px;
    line-height: 30px;
}

.about__desc-item {
    list-style: inside;
}

.about__desc2 {
    font-size: 18px;
    line-height: 30px;
    /* margin-bottom: 45px; */
}

.about__desc-text {
    margin-bottom: 15px;
    text-align: left;
}

.about__desc-list {
    margin-bottom: 30px;
    text-align: left;
}

.about__wrap {
    display: flex;
    justify-content: space-between;
    /* max-height: 430px; */
}

.about__img {
    width: 360px;
    height: 260px;
    border-radius: 5px;
    box-shadow: 10px 10px 10px 5px rgba(1, 57, 90, 0.15);
}

.img1 {
    position: relative;
    display: block;
    z-index: 1;
    top: 120px;
}

.img2 {
    position: relative;
    display: block;
    z-index: 2;
    bottom: 230px;
    left: 100px;
}

.img3 {
    position: relative;
    display: block;
    z-index: 3;
    bottom: 350px;
    left: 150px;
}

@media (min-width: 967px) {
    /* .about__imgs {
        width: 50%;        
    }     */

    .about__desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 47%;
    }

    .about__title {
        text-align: left;
    }
}

@media (max-width: 968px) {
    .about__imgs {
        transform: scale(0.7, 0.7);
        height: 320px;
        margin-top: -80px;
        margin-left: -115px;
    }

    .about__desc {
        padding-left: 15px;
        padding-right: 5px;
    }

    .imgs_wrap {
        display: flex;
        justify-content: center;
        height: 260px;
    }

    .about__wrap {
        flex-direction: column;
    }

    .about__desc0 {
        margin-bottom: 80px;
    }

    .about__desc1 {
        margin-bottom: 50px;
    }
    
    .about__desc2 {
        margin-bottom: 80px;
    }
}

@media (min-width: 1441px) {
    .about {
        max-width: 1150px;
    }

    .imgs_wrap {
        width: 50%;
    }

    .about__wrap {
        height: 590px;
        max-height: 470px;
        min-height: 470px;
}
}

@media (min-width: 969px) and (max-width: 1120px) {
    .about__wrap {
        height: 620px;
    }
}

@media (min-width: 1121px) and (max-width: 1440px) {
    .about {
        max-width: 1150px;
    }

    .imgs_wrap {
        width: 50%;
    }

    .about__wrap {
        height: 590px;
        max-height: 470px;
        min-height: 470px;
    }
}

@media (min-width: 1110px) {
    .about__desc {
        width: 53%;
    }
    .about__wrap {
        height: 500px;
    }
}