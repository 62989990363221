.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    height: 80px;
    width: 100%;
    font-weight: 500;
}

.logo-header {
    margin-top: 10px;
    margin-left: 10px;
}

.logo {
    z-index: 3;
}

.header-menu__item {
    color: #fff;
    font-weight: bold;
}


@media (min-width: 1120px) {
    .header-menu {
        display: flex;
        margin-left: 30px;
        flex-direction: row;
        list-style-type: none;
        align-items: center;
        color: white;
        z-index: 2;
    }    

    .sidebar {
        display: none;
    }
}

@media (max-width: 1119px) {
    .header-menu {
        display: none;
    }
}
