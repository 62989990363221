.projects {
    padding-top: 25px;
    padding-bottom: 25px;
    line-height: 46px;
    color: #FFF;
    font-family: "GothamProRegular";
    font-weight: 400;
    max-width: 1204px;
}
.project__link {
    text-decoration: none;
}

.project__title {
    color: black;
    font-size: 22px;
    /* margin-bottom: 25px; */
}

.project__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project__item {
    display: flex;
    margin-left: 5px;
    margin-right: 17px;
    margin-bottom: 20px;
    width: 275px;
    height: 155px;
    background-color: #9EB0BC;
}


.project__item:hover {
    background-color: #659DBF;
    box-shadow: 0px 0px 5px 5px #659DBF;
    color: antiquewhite;
    cursor: pointer; 
}

.project-title {
    font-size: 22px;
    color: black;
    text-align: left;
    padding-bottom: 15px;
}

.item__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 18px;
}

.item__title {
    font-size: 22px;
    line-height: 19.14px;
    padding-top: 30px;
}

.item__desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding-top: 10px;
    text-align: left;
    color: black;

}

@media (max-width: 1281px) {
    .project__list {
        flex-direction: row;
        margin-left: 5%;
    }
    .project-title {
        margin-left: 5%;
    }
}

@media (max-width: 1050px) {
    .project-title {
        text-align: center;
    }
}